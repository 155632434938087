@charset "UTF-8";
// ==========================================================================
// Color
// ==========================================================================
$primaryLight : #c5cae9;
$primary      : #9feaff;
$primaryDark  : #009fe8;

$secondaryExLight:  #fffde5;
$secondaryLight : #ffebd3;
$secondary      : #facd02;
$secondaryDark  : #ff8702;
$secondaryExDark: #e95513;

$black        : #3e3a39;
$white        : #fff;

$redLight     : #ffcdd2;
$red          : #c00;
$redDark      : #b71c1c;

$orangeLight  : #facd02;
$orange       : #ff8702;
$orangeDark   : #e95513;


$yellowLight  : #fff9c4;
$yellow       : #fff176;
$yellowDark   : #ffeb3b;

$greenLight   : #c8e6c9;
$green        : #4caf50;
$greenDark    : #1b5e20;

$blueLight    : #bbdefb;
$blue         : #2196f3;
$blueDark     : #0d47a1;

$grayExLight  : #f5f5f5;
$grayLight    : #e0e0e0;
$gray         : #9e9e9e;
$grayDark     : #616161;
$grayExDark   : #212121;


// Group
// --------------------------------------------------------------------------
$colorGroup: (
  primary: (
    base    : $primary,
    dark    : $primaryDark,
    light   : $primaryLight,
    text    : $primaryDark,
    bg      : $primaryLight
  ),
  secondary: (
    base    : $secondary,
    dark    : $secondaryDark,
    light   : $secondaryLight,
    exlight : $secondaryExLight,
    text    : $white,
    bg      : $secondaryLight,
    border  : $secondaryExDark
  ),
  text: (
    base    : $black,
    reverse : $grayExLight
  ),
  active: (
    base    : $blue,
    dark    : $blueDark,
    text    : $blueDark,
    bg      : $blueLight
  ),
  success: (
    base    : $green,
    dark    : $greenDark,
    text    : $greenDark,
    bg      : $grayLight
  ),
  disable: (
    base    : $gray,
    dark    : $grayDark,
    text    : $grayDark,
    bg      : $grayLight
  ),
  attention: (
    base    : $orangeLight,
    dark    : $orange,
    text    : $orangeDark,
    bg      : $orangeLight
  ),
  warning: (
    base    : $redLight,
    dark    : $red,
    text    : $redDark,
    bg      : $redLight
  ),
  mono: (
    w       : $white,
    k       : $black,
  )
);


/**************************************************
*  background-color: colors(warning,dark);
*            ↓↓↓↓↓↓
*  background-color: #ff7b7b;
**************************************************/
@function colors($key, $tone: base) {
  @return map-get(map-get($colorGroup, $key), $tone);
}

