@charset "UTF-8";
// ==========================================================================
// Mypage
// ==========================================================================
.myNav {
  ul {
    @include clearfix;
    list-style: none;
    li {
      float: left;
      margin-right: 17px;
      a:hover {
        opacity: .6;
        transition: .5s; } } } }

.myLevel {
  margin-top: 60px;
  text-align: center; }

.myComment {
  @include clearfix;
  width: 808px;
  margin: 35px auto;
  &_body {
    @include pxrem(1.8);
    position: relative;
    float: left;
    height: 250px;
    width: 639px;
    margin: 0 24px 0 0;
    padding: .8em 1em;
    border: 2px solid colors(secondary);
    border-radius: 15px;
    background-color: colors(secondary, exlight);
    font-weight: bold;
    line-height: 1.4;
    &:before {
      position: absolute;
      right: -48px;
      top: 101px;
      content: ' ';
      height: 24px;
      width: 24px;
      border-top: 14px solid transparent;
      border-right: 24px solid transparent;
      border-bottom: 14px solid transparent;
      border-left: 24px solid colors(secondary); }
    &:after {
      position: absolute;
      right: -44px;
      top: 101px;
      content: ' ';
      height: 24px;
      width: 24px;
      border-top: 14px solid transparent;
      border-right: 24px solid transparent;
      border-bottom: 14px solid transparent;
      border-left: 24px solid colors(secondary, exlight); } }
  img {
    margin-top: 30px; } }

.myMap {
  width: 798px;
  margin: 50px auto;
  padding: 13px 27px 30px;
  border-radius: 30px;
  background-color: colors(mono, w);
  text-align: center;
  &_title {
    @include pxrem(2.2);
    display: inline-block;
    min-width: 70%;
    padding: 0 30px;
    border-radius: 20px;
    background-color: colors(secondary);
    font-weight: bold;
    text-align: center; }
  &_image {
    img {
      border-radius: 20px; } }
  &_detail {
    display: table;
    width: 100%;
    &_steps {
      display: table-cell;
      width: 50%;
      text-align: right;
      &_title {
        @include pxrem(2.3);
        margin-bottom: 30px;
        padding: 0 35px 11px 35px;
        border-bottom: 2px solid colors(secondary);
        font-weight: bold; }
      &_text {
        @include pxrem(1.8);
        margin: 0 0 12px;
        padding-right: 35px; } }
    &_distance {
      display: table-cell;
      width: 50%;
      text-align: left;
      &_title {
        @include pxrem(2.3);
        margin-bottom: 30px;
        padding: 0 35px 11px 35px;
        border-bottom: 2px solid colors(secondary);
        font-weight: bold; }
      &_text {
        @include pxrem(1.8);
        margin: 0 0 12px;
        padding-left: 35px; } } }
  &_comment {
    @include pxrem(1.8);
    width: 100%;
    margin: 30px 0 0 0;
    padding: .8em 1em;
    border: 2px solid colors(secondary);
    border-radius: 15px;
    background-color: colors(secondary, exlight);
    font-weight: bold;
    line-height: 1.4;
    em {
      color: #ce2f51;
      font-style: normal; } } }

.myReaching {
  width: 798px;
  margin: 50px auto;
  padding: 18px 27px 10px;
  border-radius: 30px;
  background-color: colors(mono, w);
  text-align: center;
  &_title {
    @include pxrem(2.2);
    display: inline-block;
    min-width: 70%;
    padding: 0 30px;
    margin: 0;
    border-radius: 20px;
    background-color: colors(secondary);
    font-weight: bold;
    text-align: center; }
  &_list {
    @include clearfix;
    width: 596px;
    margin: 20px auto;
    &_item {
      float: left;
      margin-right: 20px;
      &:last-child {
        margin-right: 0; }
      &_text {
        margin: 0;
        padding: 0; } } } }
