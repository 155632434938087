@charset "UTF-8";
// ==========================================================================
// Top
// ==========================================================================
.top {
  width: 830px;
  margin: 0 auto 100px auto;
  &_title {
    text-align: center;
    margin: 30px 0;
    background-color: colors(mono, w); }
  &_news {
    &.news {
      @include clearfix();
      @include pxrem(1.8);
      margin-bottom: 40px;
      .news {
        &_title {
          float: left;
          width: 70px;
          margin: 0; }
        &_body {
          height: 160px;
          margin-left: 80px;
          overflow: scroll;
          dl {
            @include clearfix;
            margin: 0;
            dt {
              display: block;
              float: left;
              width: 5em;
              text-align: right; }
            dd {
              display: block;
              margin-left: 6em;
              margin-bottom: 1em; } } } } } }
  &_contents {
    @include pxrem(1.6);
    margin-bottom: 30px;
    padding: 2em;
    border-radius: 20px;
    background-color: colors(secondary, exlight);
    text-align: justify;
    text-justify: inter-ideograph; }
  &_enter {
    text-align: center;
    a {
      margin-right: 40px; } } }

