@charset "UTF-8";
// ==========================================================================
// Disclaimer
// ==========================================================================
.disclaimer {
  @include pxrem(1.5);
  margin: 30px 50px;
  line-height: 1.7;
  text-align: justify;
  text-justify: inter-ideograph; }
