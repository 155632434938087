@charset "UTF-8";
// ==========================================================================
// Popup
// ==========================================================================
body.is-lock {
  overflow: hidden; }

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  &.is-active {
    display: flex;
 }    //animation: anime-popup 1s ease
  &:after {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    width: 100%;
    content: ' ';
    background-color: #000;
    opacity: .3;
    z-index: 10000; }
  &_body {
    width: 898px;
    max-height: 100%;
    padding: 30px 0;
    border-radius: 30px;
    background-color: colors(secondary, light);
    z-index: 100000;
    -webkit-transform: translate3d(0,0,0); // Safari bugfix
    &_scroll {
      overflow: auto; } } }


@keyframes anime-popup {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
