@charset "UTF-8";
// ==========================================================================
// Base
// ==========================================================================
html {
  position: relative;
  font-size: 62.5%;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  @include mq(pc) {
    min-width: 957px; }
  @include mq(sp) {
    min-width: 320px; } }


body {
  @include pxrem(1.4);
  width: 100%;
  min-width: 957px;
  margin: 0;
  padding: 0;
  background-color: colors(primary);
  color: colors(text);
  line-height: 1.777; }

*, *::before, *::after {
  box-sizing: border-box; }

img {
  max-width: 100%;
  vertical-align: middle; }



// ==========================================================================
// Font family
// ==========================================================================
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }


@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }


@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }


@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }


@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100; }


@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200; }


body, tooltip, popover {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif; }

// 下記monospaceは、初期値です
code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

// IE10 YuGothic Hack
@media all and (-ms-high-contrast: none) {
  body, tooltip, popover {
    font-family: Verdana, Meiryo, sans-serif; } }
