@charset "UTF-8";
// ==========================================================================
// Meal
// ==========================================================================
.mealLeft {
  float: left;
  width: 149px;
  &_item {
    display: block;
    width: 100%;
    margin-bottom: 25px;
    &.dropdown {
      .dropdown_title {
        @include pxrem(2); }
      .dropdown_body {
        @include pxrem(2);
        width: 149px; } } } }

.mealMain {
  float: left;
  width: 481px;
  margin: 0 27px;
  &_content {
    &.searchBar {
      @include clearfix;
      margin-bottom: 30px;
      .input-searchBar {
        float: left;
        width: 380px; }
      .btn-image {
        float: right;
        padding: 0; } }
    &.foodList {
      width: 100%;
      padding: 32px 22px;
      border-radius: 30px;
      background-color: colors(mono, w);
      .foodList {
        &_scrollInner {
          @include clearfix;
          height: 626px;
          overflow: auto; }
        &_item {
          float: left;
          width: 33%;
          margin: 0 0.16% 30px;
          text-align: center;
          cursor: pointer;
          &_name {
            @include pxrem(1.5);
            margin: 5px 0 0; } } } } } }

.mealRight {
  float: left;
  width: 213px;
  &_date {
    @include pxrem(1.9);
    height: 51px;
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
    border-radius: 30px;
    background-color: colors(mono, w);
    color: colors(secondary, dark);
    text-align: center;
    line-height: 51px; }
  .selectedMenu {
    height: 690px;
    border-radius: 30px;
    background-color: colors(mono, w);
    &_title {
      @include pxrem(2);
      margin: 0 15px;
      padding: 10px 0;
      text-align: center;
      background: url('/images/pc/common/border_dots.png') left bottom repeat-x; }
    &_scrollInner {
      height: 590px;
      margin: 20px 10px;
      overflow: scroll; }
    &_subtitle {
      @include pxrem(1.9);
      margin: 0;
      color: colors(secondary, dark);
      font-weight: normal; }
    &_list {
      margin: 0 0 30px;
      padding-left: 2em;
      line-height: 1.4;
      li {
        margin-bottom: .3em; } } } }

.mealPopup {
  position: relative;
  &-1 {
    padding: 15px 45px 2px 45px;
    &_title {
      @include pxrem(4);
      margin: 0 auto 30px;
      border-radius: 30px;
      background-color: colors(mono, w);
      font-weight: normal;
      text-align: center; }
    &_left {
      @include pxrem(2.3);
      float: left;
      width: 404px;
      &_info {
        margin: 13px 0;
        text-align: center; } }
    &_right {
      @include pxrem(2);
      float: right;
      margin-top: -4px;
      margin-left: 27px;
      font-weight: bold;
      &_form {
        margin-bottom: 22px;
        list-style: none;
        .label-radio {
          margin-right: 1em; }
        li {
          margin-bottom: 15px;
          text-align: right; } } } }
  &-2 {
    padding: 10px 45px 2px 45px;
    &_title {
      @include pxrem(3);
      margin: 0 auto 15px;
      font-weight: normal;
      text-align: center; }
    &_body {
      @include clearfix;
      &_item {
        @include pxrem(2.3);
        float: left;
        width: 20%;
        margin-bottom: 20px;
        padding: 0 12.5px;
        img {
          width: 100%;
          margin-bottom: 5px; }
        .btn {
          @include pxrem(1.4); } } } }
  &-3 {
    padding: 32px 45px 2px 45px;
    &_title {
      @include pxrem(4);
      margin-bottom: 100px;
      font-weight: normal;
      text-align: center; }
    &_body {
      @include clearfix;
      &_item {
        @include pxrem(2.3);
        margin-bottom: 80px;
        text-align: center; } }
    .btnGroup {
      position: absolute;
      right: 40px;
      bottom: 40px; } } }
