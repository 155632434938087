@charset "UTF-8";
// ==========================================================================
// Main
// ==========================================================================
.wrapper {
  min-width: 957px; }
.pageTitle {
  @include pxrem(3.1);
  position: relative;
  width: 100%;
  min-height: 70px;
  min-width: 957px;
  margin: 0 0 43px;
  background-color: colors(primary, dark);
  color: colors(mono, w);
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    vertical-align: middle; } }

.main {
  position: relative;
  width: 957px;
  margin: auto;
  padding: 30px;
  border-radius: 29px;
  background-color: colors(secondary, light);
  color: colors(text); }

.content {
  overflow: hidden;
  position: relative;
  width: 100%;
  &_title {
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align: center;
    font-weight: bold; } }

.step {
  @include pxrem(2.5);
  position: absolute;
  top: -35px;
  height: 132px;
  width: 132px;
  border-radius: 100%;
  background-color: colors(mono, w);
  color: colors(secondary, dark);
  font-weight: bold;
  text-align: center;
  line-height: 1.3;
  p {
    display: table-cell;
    height: 132px;
    width: 132px;
    margin: 0;
    padding: 0;
    text-align: center;
    vertical-align: middle; }

  &_now {
    @include pxrem(4.1); } }
