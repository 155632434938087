@charset "UTF-8";
// ==========================================================================
// Result
// ==========================================================================

%resultTitle {
  position: relative;
  height: 50px;
  width: 957px;
  margin-top: 30px;
  margin-left: -30px;
  background-color: colors(secondary);
  color: colors(mono, k);
  text-align: center;
  line-height: 50px;
  a {
    @include pxrem(1.9);
    position: absolute;
    display: block;
    top: 5px;
    right: 30px;
    height: 40px;
    padding: 0 1em;
    border-radius: 18px;
    text-decoration: none;
    line-height: 40px; } }

.generalComment {
  @include pxrem(1.8);
  margin: 0;
  padding: .8em 1em;
  border: 2px solid colors(secondary);
  border-radius: 15px;
  background-color: colors(secondary, exlight);
  font-weight: bold;
  line-height: 1.4; }

.masterLevel {
  display: table;
  width: 100%;
  margin: 20px 0;
  &_link {
    position: relative;
    height: 50px;
    width: 957px;
    margin-left: -30px;
    color: colors(mono, k);
    text-align: center;
    line-height: 50px;
    a {
      @include pxrem(1.9);
      position: absolute;
      display: block;
      top: 5px;
      right: 30px;
      height: 40px;
      padding: 0 1em;
      border-radius: 18px;
      text-decoration: none;
      line-height: 40px; } }
  &_left {
    display: table-cell;
    width: 50%;
    padding-right: 40px; }
  &_right {
    display: table-cell;
    width: 50%;
    vertical-align: bottom; }
  &_title {
    @include pxrem(2.7);
    position: relative;
    display: block;
    width: 200px;
    margin: 0 0 0 auto;
    padding: 45px 0;
    border-radius: 15px;
    background-color: colors(secondary);
    text-align: center;
    &:after {
      position: absolute;
      content: '';
      top: calc(50% - 15px);
      right: -15px;
      height: 0;
      width: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 15px solid colors(secondary); } } }

.score {
  @include clearfix;
  &_left {
    float: left;
    height: 134px; }
  &_right {
    float: right;
    width: 50%; }
  &_title {
    @include pxrem(2.3);
    float: left;
    height: 134px;
    width: 227px;
    margin: 0;
    padding: 35px 0;
    border-radius: 15px 0 0 15px;
    background-color: colors(mono, w);
    color: colors(mono, k);
    text-align: center;
    line-height: 1.3;
    &_date {
      @include pxrem(1.7);
      color: colors(secondary, dark); } }
  &_num {
    @include pxrem(8);
    position: relative;
    float: left;
    height: 134px;
    width: 188px;
    margin: 0;
    border-radius: 0 15px 15px 0;
    background-color: colors(secondary, dark);
    color: colors(mono, w);
    font-weight: bold;
    text-align: center;
    line-height: 120px;
    &_denom {
      @include pxrem(2.1);
      position: absolute;
      display: block;
      bottom: 10px;
      right: 15px;
      line-height: 1; } } }

.userAnalysis {
  &_title {
    @extend %resultTitle; }
  &_item {
    &_title {
      @include pxrem(2);
      padding: 0;
      margin-bottom: .3em; }
    &_body {
      &.mealTable {
        width: 100%;
        margin-bottom: 20px;
        border-radius: 15px;
        border-collapse: collapse;
        background-color: colors(mono, w);
        tr {
          border-bottom: 2px dashed colors(secondary, light);
          &:last-child {
            border: 0; } }
        .mealTable {
          &_title {
            @include pxrem(3.5);
            width: 10%;
            &-breakfast {
              @extend .mealTable_title;
              color: #ff6f9a; }
            &-lunch {
              @extend .mealTable_title;
              color: #ff8702; }
            &-dinner {
              @extend .mealTable_title;
              color: #4c9ad8; }
            &-snack {
              @extend .mealTable_title;
              color: #999; } }
          &_calorie {
            @include pxrem(1.6);
            font-weight: bold;
            white-space: nowrap; }
          &_meals {
            @include pxrem(1.4);
            width: 80%;
            padding: .8em 1em .8em 0;
            text-align: justify;
            text-justify: inter-ideograph; } } } } } }

.balance {
  @include clearfix;
  &_title {
    @extend %resultTitle; }
  &_type {
    @include clearfix;
    &_item {
      float: left;
      width: 19%;
      margin-bottom: 10px;
      margin-right: 1.25%;
      text-align: center;
      &:last-child {
        margin-right: 0; }
      &_title {
        width: 100%;
        border-radius: 10px;
        color: colors(mono, w);
        text-align: center;
        &-staple {
          @extend .balance_type_item_title;
          background-color: #dc8a1c; }
        &-main {
          @extend .balance_type_item_title;
          background-color: #ce2f51; }
        &-sub {
          @extend .balance_type_item_title;
          background-color: #638d4c; }
        &-milk {
          @extend .balance_type_item_title;
          background-color: #8c51a5; }
        &-fruit {
          @extend .balance_type_item_title;
          background-color: #2c729f; } }
      img {
        margin-bottom: 10px; } } }
  .generalComment {
    float: left;
    height: 253px;
    width: 625px; }
  .radarChart {
    float: right;
    height: 253px;
    width: 253px;
    border-radius: 10px;
    background-color: colors(mono, w);
    #chart {
      height: 253px;
      width: 253px; } } }

.rhythm {
  &_title {
    @extend %resultTitle; }
  &_barGraph {
    width: 100%;
    margin-bottom: 20px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 0;
      li {
        @include pxrem(1.8);
        display: inline-block;
        overflow: hidden;
        padding: .8em 0;
        border: 2px solid colors(mono, w);
        border-right: 0;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        white-space: nowrap;
        &:first-child {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px; }
        &:last-child {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          border-right: 2px solid colors(mono, w); } } }
    &-breakfast {
      background-color: #ffc9e2; }
    &-lunch {
      background-color: #ffd8c7; }
    &-dinner {
      background-color: #c0e0ff; }
    &-snack {
      background-color: #ccc; } } }




.grid-auto {
  &_item {
    display: inline-block;
    margin-right: 10px;
    &:nth-child(even) {
      margin-right: 0; } } }

.pill {
  font-size: 0;
  margin-bottom: 20px;
  &_title {
    @include pxrem(2);
    display: inline-block;
    margin: 0;
    padding: .5em 1em;
    border-radius: 15px 0 0 15px;
    background-color: colors(secondary); }
  &_body {
    @include pxrem(2);
    display: inline-block;
    margin: 0;
    padding: .5em 1em;
    border-radius: 0 15px 15px 0;
    background-color: colors(mono, w);
    font-weight: bold;
    &_anotation {
      @include pxrem(1.6);
      padding-left: 1em;
      color: colors(warning, dark);
      font-weight: normal;
      em {
        font-weight: bold;
        text-decoration: none;
        font-style: normal; } } } }

/* ----Okada Start---- */
.resultcommentary_title {
  font-size: 2em;
  position: relative;
  height: 50px;
  width: 957px;
  margin: 0 0 25px -30px;
  background-color: #facd02;
  color: #3e3a39;
  text-align: center;
  line-height: 50px; }

.resultcommentary_subtitle {
  border-color: #ffc400;
  border-style: solid;
  border-width: 2px 2px 0;
  background-color: #fff1c5;
  text-align: center;
  font-size:  1.5em;
  padding: 3px;
  margin: 0;
  border-radius: 15px 15px 0 0; }

.resultcommentary_box {
  border-color: #ffc400;
  border-style: solid;
  border-width: 0 2px 2px;
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  margin-bottom: 25px;
  padding: 20px 30px; }

.resultcommentary_bg_1 {
  background: #fff url('/images/pc/result/resultcommentary01.jpg') no-repeat 615px 40px; }

.resultcommentary_bg_2 {
  background: #fff url('/images/pc/result/resultcommentary02.jpg') no-repeat 635px 50px; }

.resultcommentary_bg_3 {
  background: #fff url('/images/pc/result/resultcommentary03.jpg') no-repeat 580px 45px; }

.resultcommentary_box p {
  font-weight: bold;
  font-size:  1.3em;
  margin: 0 10px 25px; }

.resultcommentary_box .small {
  font-size:  1.1em;
  font-weight: normal; }

.resultcommentary_caption {
  text-align: center;
  padding: 3px;
  margin: 0 0 20px;
  font-size:  1.4em;
  background-color: #facd02;
  border-radius: 10px; }

.resultcommentary_formula {
  border: solid 2px #ffc400;
  background-color: #fffde5;
  text-align: center;
  padding: 30px 0px;
  border-radius: 10px; }

.resultcommentary_comment {
  width: 808px;
  margin: 0 auto; }

.resultcommentary_comment::after {
  content: '';
  display: block;
  clear: both; }

.resultcommentary_comment_body {
  position: relative;
  float: left;
  height: 170px;
  width: 640px;
  margin: 0 24px 0 0;
  padding: .8em 1em;
  border: 2px solid #facd02;
  border-radius: 15px;
  background-color: #fffde5; }

.resultcommentary_comment p {
  font-size:  1.1em;
  font-weight: bold; }

.resultcommentary_comment_body:before {
  position: absolute;
  right: -48px;
  top: 70px;
  content: ' ';
  height: 24px;
  width: 24px;
  border-top: 14px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #facd02; }

.resultcommentary_comment_body:after {
  position: absolute;
  right: -44px;
  top: 70px;
  content: ' ';
  height: 24px;
  width: 24px;
  border-top: 14px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #fffde5; }

.resultcommentary_comment img {
  margin: 13px 0 0 20px; }

.resultcommentary_comment_s {
  width: 808px;
  margin: 35px auto; }

.resultcommentary_comment_s::after {
  content: '';
  display: block;
  clear: both; }

.resultcommentary_comment_body_s {
  position: relative;
  float: left;
  height: 123px;
  width: 640px;
  margin: 0 24px 0 0;
  padding: 2em 1em;
  border: 2px solid #facd02;
  border-radius: 15px;
  background-color: #fffde5; }

.resultcommentary_comment_s p {
  font-size:  1.1em;
  font-weight: bold; }

.resultcommentary_comment_body_s:before {
  position: absolute;
  right: -48px;
  top: 46px;
  content: ' ';
  height: 24px;
  width: 24px;
  border-top: 14px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #facd02; }

.resultcommentary_comment_body_s:after {
  position: absolute;
  right: -44px;
  top: 46px;
  content: ' ';
  height: 24px;
  width: 24px;
  border-top: 14px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #fffde5; }

.resultcommentary_comment_s img {
  margin: -10px 0 0 20px; }

.resultcommentary_text {
  margin: 0 30px 30px; }

.resultcommentary_text p {
  font-weight: bold;
  font-size:  1.2em; }

.resultcommentary_text .small {
  font-weight: normal;
  font-size:  1.2em; }

.resultcommentary_caption_check {
  margin: 0 0 10px 10px;
  padding: 7px 0 7px 60px;
  font-size:  1.4em;
  background: url('/images/pc/result/point_check.png') no-repeat center left; }

.resultcommentary_list_pin {
  list-style: none;
  margin: 0 0 20px 60px; }

.resultcommentary_list_pin li {
  font-size:  1.1em;
  padding: 3px 0 3px 30px;
  background: url('/images/pc/result/point_pin.png') no-repeat; }

.resultcommentary_caption_pin {
  font-size:  1.4em;
  padding: 0px 0 0px 35px;
  margin: 0 0 10px 0;
  background: url('/images/pc/result/point_pin.png') no-repeat; }

p.resultcommentary_comment {
  font-weight: normal;
  font-size:  1.2em;
  background-color: #fff7e2;
  border-radius: 10px;
  padding: 15px 25px; }

.generalComment_small {
  font-size:  1.1em;
  margin: 0 0 20px;
  padding: .8em 1em;
  border: 2px solid #facd02;
  border-radius: 15px;
  background-color: #fffde5;
  font-weight: bold;
  line-height: 1.5; }

.generalComment_small a {
  font-weight: normal; }

.tbl-m,
.tbl-s {
  border: solid 2px #ffc400;
  margin: 0 auto 30px;
  border-collapse: collapse; }

.tbl-m {
  width: 500px; }

.tbl-s {
  width: 450px; }

.tbl-m th,
.tbl-s th {
  border: solid 1px #ffc400;
  background-color: #fff1c5;
  font-size:  1.1em;
  padding: 0; }

.tbl-m td,
.tbl-s td {
  border-color: #ccc;
  border-style: solid dotted;
  border-width: 1px;
  font-size:  1.1em;
  text-align: center;
  padding: 2px 0; }

.tr-o tr:nth-child(odd),
.tr-e tr:nth-child(even) {
  background-color: #eee; }

.tr-o tr:nth-child(even),
.tr-e tr:nth-child(odd) {
  background-color: #fff; }

.tbl-l {
  width: 90%;
  border: solid 2px #ffc400;
  margin: 0 auto 30px;
  border-collapse: separate;
  border-radius: 15px;
  border-spacing: initial; }

.tbl-l th {
  background-color: #ffc400;
  text-align: center;
  width: 150px; }

.tbl-l th,
.tbl-l td {
  padding: 5px 25px;
  font-size:  1.2em; }

.tbl-l-t {
  border-radius: 10px 0 0 0;
  border-bottom: 2px dotted #fff; }

.tbl-l-td {
  border-bottom: 2px dotted #ffc400; }

.tbl-l-b {
  border-radius: 0 0 0 10px; }

.tbl-l-m {
  border-radius: 10px 0 0 10px; }

.u-mb-n {
  margin-bottom: 10px; }
/*----Okada End----*/
