@charset "UTF-8";
// ==========================================================================
// Form
// ==========================================================================
.form {
  @include pxrem(2.5);
  display: block;
  line-height: 1;
  &_item {
    display: table;
    width: 100%;
    padding-top: 35px;
    &:last-child {
      padding-bottom: 35px; }
    &_title {
      display: table-cell;
      width: 45%;
      text-align: right; }
    &_body {
      display: table-cell;
      font-weight: bold;
      text-align: left;
      vertical-align: middle; } } }

.label {
  &-left {
    padding-right: 15px; }
  &-radio {
    margin-right: 2em;
    font-weight: bold;
    vertical-align: bottom;
    cursor: pointer;
    &:last-child {
      margin-right: 0; } } }

%setting {
  @include pxrem(2.5);
  height: 51px;
  border: 1px solid colors(secondary);
  border-radius: 13px;
  background-color: colors(mono, w);
  color: colors(text);
  font-weight: bold;
  vertical-align: middle;
  outline: 0; }

.input {
  @extend %setting;
  padding: 0 1em;
  &-middle {
    width: 355px; }
  &-searchBar {
    border: 1px solid #b28146; } }

.select {
  @extend %setting;
  appearance: none;
  background: colors(mono, w) url('/images/pc/common/select_arrow.png') no-repeat;
  text-align: center;
  cursor: pointer;
  &:focus {
    outline: 0; }
  &-middle {
    width: 234px;
    padding: 0 36px 0 1em;
    background-position: 234 - 36px center; }
  &-small {
    width: 120px;
    padding: 0 36px 0 1em;
    background-position: 120 - 36px center; }
  &-year {
    width: 6em;
    padding: 0 36px 0 .5em;
    background-position: 150 - 36px center; }
  &-month, &-day {
    width: 4em;
    padding: 0 36px 0 .5em;
    background-position: 100 - 36px center; } }

select::-ms-expand {
  display: none; }

.radio {
  display: inline-block;
  appearance: none;
  -moz-appearance: radio;
  height: 22px;
  width: 22px;
  border: 1px solid colors(secondary);
  border-radius: 100%;
  background-color: colors(mono, w);
  vertical-align: middle;
  cursor: pointer;
  &:checked {
    background-color: colors(secondary);
    box-shadow:  0 0 0 5px colors(mono, w) inset; }
  &:focus {
    outline: 0; }
  &[type="radio"] {
    margin: -2px .3em 0 0; } }

