@charset "UTF-8";
// ==========================================================================
// Border
// ==========================================================================
hr {
  display: block;
  margin: 25px 0;
  height: 7px;
  border: 0;
  background: url('/images/pc/common/border_dots.png') repeat-x; }
