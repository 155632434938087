@charset "UTF-8";
// ==========================================================================
// Dropdown
// ==========================================================================
.dropdown {
  position: relative;
  margin: 0;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    .dropdown_title {
      opacity: .7;
      animation: anime-dropdown-title .5s ease; }
    .dropdown_body {
      position: absolute;
      display: block;
      opacity: .95;
      animation: anime-dropdown-body .5s ease; } }
  &_title {
    margin: 0;
    padding: 10px;
    border: 1px solid colors(secondary, dark);
    border-radius: 13px 13px 0 0;
    background-color: colors(secondary);
    text-align: center;
    .is-active & {
      opacity: .7;
      animation: anime-dropdown-title .5s ease; } }
  &_body {
    display: none;
    z-index: 10000;
    .is-active & {
      position: absolute;
      display: block;
      opacity: .95;
      animation: anime-dropdown-body .5s ease; }
    &_name {
      margin: 0;
      padding: 5px;
      border: 1px solid colors(secondary, dark);
      border-top: 0;
      background-color: colors(mono, w);
      text-align: center;
      &:last-child {
        border-radius: 0 0 13px 13px; }
      &:hover {
        background-color: colors(secondary, light); } } } }

@keyframes anime-dropdown-body {
  0% {
    opacity: 0; }
  100% {
    opacity: .95; } }

@keyframes anime-dropdown-title {
  0% {
    opacity: 1; }
  100% {
    opacity: .7; } }
