@charset "UTF-8";
// ==========================================================================
// Header
// ==========================================================================
.header {
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  min-width: 957px;
  background-color: colors(mono, w);
  &_logo {
    margin: 0 0 0 -7px;
    padding: 0;
    img {
      margin: 0; } }
  &_logout {
    position: absolute;
    top: 17.5px;
    right: 15px;
    height: 45px;
    button {
      @include pxrem(2);
      appearance: none;
      height: 45px;
      padding: 0 1em;
      border: 2px solid colors(primary, dark);
      border-bottom: 4px solid colors(primary, dark);
      border-radius: 13px;
      background-color: colors(mono, w);
      color: colors(mono, k);
      text-align: center;
      cursor: pointer;
      &:hover {
        border: 2px solid colors(primary, dark);
        border-top: 4px solid colors(primary, dark); } } } }

