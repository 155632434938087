@charset "UTF-8";
// ==========================================================================
// Main
// ==========================================================================
.btn {
  appearance: none;
  margin-right: 22px;
  padding: 0;
  border: 0;
  cursor: pointer;
  outline: 0;
  &:last-child {
    margin-right: 0; }
  &:hover {
    opacity: .8; }
  &-text {
    border: 2px solid colors(secondary, border);
    border-radius: 13px;
    background-color: colors(secondary, dark);
    color: colors(mono, w); }
  &-image {
    border: 0;
    background-color: transparent; }
  &-middle {
    @include pxrem(2.3);
    width: 234px;
    padding: 17px;
    line-height: 1; }
  &-expand {
    display: block;
    width: 100%; }
  &-block {
    display: block; } }

.btnGroup {
  display: block;
  &-center {
    text-align: center; }
  &-right {
    text-align: right; }
  &-mtLarge {
    margin-top: 90px; }
  &-mtMedium {
    margin-top: 60px; }
  &-mtSmall {
    margin-top: 25px; } }
