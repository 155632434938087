@charset "UTF-8";
// ==========================================================================
// Align
// ==========================================================================
.u {
  &-tal {
    text-align: left; }

  &-tac {
    text-align: center; }

  &-tar {
    text-align: right; }

  &-vat {
    vertical-align: top; }

  &-vam {
    vertical-align: middle; }

  &-vab {
    vertical-align: bottom; }
  &-fl {
    float: left; }
  &-fr {
    float: right; } }
