@charset "UTF-8";
// ==========================================================================
// Mixin
// ==========================================================================

// Font size
// --------------------------------------------------------------------------
@mixin pxrem($s:1.4) {
  font-size: ($s * 10) + px;
  font-size: $s + rem; }

// Media query
// --------------------------------------------------------------------------
$breakpoint: ("sp": "screen and (max-width: 39.9375em)", "pc": "screen and (min-width: 40em)") !default;

@mixin mq($type) {
  @if map-has-key($breakpoint, $type) {
    @media #{inspect(map-get($breakpoint, $type))} {
      @content; } }
  @else {
    @warn "Warning: N/A `#{$type}`. "; } }


// Clear fix
// --------------------------------------------------------------------------
@mixin clearfix {
  &::after {
    content: '';
    display: block;
    clear: both; } }

@mixin clearfix-legacy {
  &::before, &::after {
    content: ' ';
    display: table; }
  &::after {
    clear: both; } }
