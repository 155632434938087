@charset "UTF-8";
// ==========================================================================
// Password
// ==========================================================================
.password {
  text-align: center;
  &_text {
    @include pxrem(2.5);
    font-weight: bold;
    margin-bottom: 30px; }
  &_pass {
    @include pxrem(4);
    font-weight: normal; }
  &_merit {
    @include pxrem(1.5);
    margin-top: 30px;
    text-align: left; } }
